const actions = [
  {
    title: 'My profile',
    route: '/u/profile',
    icon: 'bi-person-circle',
  },
  {
    title: 'My password',
    route: '/u/password',
    icon: 'bi-lock-fill',
  },
];

const profile = [
  {
    type: 'text',
    name: 'first_name',
    label: 'Firstname',
    placeholder: 'Your firstname',
    component: 'el-input',
    required: true,
    pattern:/^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your firstname is not valid',
  },
  {
    type: 'text',
    name: 'last_name',
    label: 'Nom de famille',
    placeholder: 'Votre nom de famille',
    component: 'el-input',
    required: true,
    pattern: /^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your lastname is not valid'
  },
  {
    type: 'text',
    name: 'username',
    label: "Nom d'utilisateur",
    placeholder: "Nom d'utilisateur",
    component: 'el-input',
    required: true,
    pattern: /^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your display name is not valid',
  },
  {
    type: 'text',
    name: 'email',
    label: 'Adresse e-mail',
    placeholder: 'Votre adresse e-mail',
    component: 'el-input',
    required: true,
    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    errorMessage: 'Your email address is not valid',
  },
  {
    type: 'text',
    name: 'company',
    label: 'Company',
    placeholder: 'your company name',
    required: false,
    component: 'el-input',
    pattern: /^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your company name is not valid',
  },
]

export { actions, profile };
